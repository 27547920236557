<template lang="html">
  <div id="properties-page">

    <Searchbar1Component></Searchbar1Component>

    <section class="properties-section" v-bind:class="{ 'only-list' : displayAs == 'list' }">
      <div class="container oversized-container">
        <div class="row">
          <!-- Map -->
          <!-- https://www.npmjs.com/package/vue2-google-maps -->
          <!-- https://xkjyeah.github.io/vue-google-maps/autoapi.html -->
          <div class="col-lg-7 col-xl-6 col-map" v-if="displayAs == 'map'">
            <div class="box-map" id="box-map-container">
              <GmapMap
                :center="mapCenter"
                :zoom="11"
                v-if="showMap">
                <GmapMarker
                  :key="'marker-'+mInx"
                  v-for="(m, mInx) in properties"
                  :position="{lat: m.lat, lng: m.lng}"
                  :title="m.name"
                  :clickable="true"
                  :draggable="false"
                  :icon="{ url: 'public/images/shared/map-icon.svg'}"
                  @mouseover="markAction(m)"
                  @mouseout="windowOpen = false"
                  @click="$router.push('/property/'+m.id)"
                />

                <gmap-info-window
                  @closeclick="windowOpen = false"
                  :opened="windowOpen"
                  :position=" {lat: gmapInfo.lat, lng: gmapInfo.lng}"
                  :options="{
                    pixelOffset: {
                      width: 0, height: -35
                    }
                  }"
                >
                  <router-link class="gmap-box-info" :to="('/property/'+gmapInfo.id)">
                    <div class="box-info">
                      <div class="photo">
                        <div class="placed-backg img" v-bind:style="{ backgroundImage: 'url('+gmapInfo.imageUrl+')' }"></div>
                      </div>

                      <h5 class="price" v-if="$root.currency == 'usd'">${{ formatNum(gmapInfo.price_usd) }} USD</h5>
                      <h5 class="price" v-else-if="$root.currency == 'mxn'">${{ formatNum(gmapInfo.price_mxn) }}<span v-if="gmapInfo.price == 0 || gmapInfo.price == null"> USD </span> <span v-else> MXN</span></h5>

                      <div class="box-descr">
                        {{ gmapInfo.content  }}
                      </div>
                    </div>

                    <div class="box-bottom">
                      {{ gmapInfo.name }}<br> {{ gmapInfo.zipcode }}
                    </div>
                  </router-link>
                </gmap-info-window>
              </GmapMap>
              <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15007912.728453133!2d-111.64064161919171!3d23.31426414371436!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x84043a3b88685353%3A0xed64b4be6b099811!2zTcOpeGljbw!5e0!3m2!1ses!2smx!4v1669670738643!5m2!1ses!2smx" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> -->
            </div>
          </div>
          <!--  -->

          <!-- Properties -->
          <div class="col-lg-5 col-xl-6 col-properties">
            <div class="row row-top">
              <div class="col-12 col-top">
                <div class="col-7 col-title">
                  <h5>
                    {{ $t("result.properties") }}
                    <span v-if="!this.$route.query.buyrent"></span>
                    <span v-if="this.$route.query.buyrent == 1"> {{ $t("result.properties_forsale") }}</span>
                    <span v-if="this.$route.query.buyrent == 2"> {{ $t("result.properties_forrent") }}</span>
                  </h5>
                </div>

                <div class="col-5 col-button">
                  <router-link class="btn btn-sm btn-s1 blue btn-bg" to="/sell-with-us">{{ $t("result.sellaproperty") }}</router-link>
                </div>

                <div class="col-12 col-text">
                  <p>{{ $t("result.subtitleproperties") }}</p>
                </div>
              </div>

              <div class="col-12 col-filters">
                <div class="col col-order">
                  <b-form-group
                    :label='$t("result.filter")'>
                    <b-form-select v-model="form.order">
                      <b-form-select-option value="1">{{ $t("result.filter_pricelow") }}</b-form-select-option>
                      <b-form-select-option value="2">{{ $t("result.filter_pricehigh") }}</b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                </div>

                <div class="col col-display">
                  <a class="link-display list" v-bind:class="{ 'active' : displayAs == 'list' }" @click="displayAs = 'list'">{{ $t("result.filter_list") }}</a>
                  <a class="link-display map"  v-bind:class="{ 'active' : displayAs == 'map' }" @click="displayAs = 'map'">{{ $t("result.filter_map") }}</a>
                </div>
              </div>
            </div>

            <div class="row row-middle">
              <div class="col-12 col-results-info">
                <h5>{{pagination.total_properties}} {{ $t("result.filter_propeties") }}</h5>
              </div>

              <div class="col-md-6 col-lg-12 col-xl-6 col-property-sample-1" v-for="(prop, prInx) in properties" :key="'prInx-'+prInx">
                <router-link class="box-link" :to="'/property/'+prop.id">
                  <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url('+prop.imageUrl+')' }">
                    <img src="public/images/pages/properties/property.png">
                  </div>

                  <div class="box-text">
                    <h5 class="name">{{ prop.name }}</h5>
                    <h6 class="price" v-if="$root.currency == 'usd'">$ {{ formatNum(prop.price_usd) }} USD</h6>
                    <h6 class="price" v-else-if="$root.currency == 'mxn'">$ {{ formatNum(prop.price_mxn) }}<span v-if="prop.price == 0 || prop.price == null"> USD </span> <span v-else> MXN</span></h6>


                    <div class="box-prop-info">
                      <!-- <span>SIZE: {{ prop.meters_ground}} FT</span> -->
                      <span style="padding-right: 0px">{{ prop.toilets}} {{ $t("result.result_bath") }}</span><span style="padding:0px" v-if="prop.toilets_partial != null && prop.toilets_partial != 0">, {{prop.toilets_partial}} {{ $t("result.result_bath_partial") }}</span>

                      <span>{{ prop.bedrooms}} {{ $t("result.result_bed") }}</span>
                      <!-- <span>{{ prop.size_interior}} </span> -->

                    </div>
                  </div>
                </router-link>
              </div>
            </div>

            <div class="row" v-if="properties.length > 0">
                <div class="col-12 text-center">
                  <div class="d-inline-block mx-0 col-pagination-sample-1">
                    <b-pagination-nav :link-gen="linkGen" :number-of-pages="pagination.total" use-router></b-pagination-nav>
                  </div>
                </div>
            </div>
          </div>
          <!--  -->
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import Searchbar1Component from './searchbar-1-component.vue';
export default {
  components: {
    Searchbar1Component
  },

  data(){
    return {
      // == map scroll values ==
      showMap: false, // tru, false para mostrar el mapa (el plugin)
      windowOpen: false, // true, false para mostrar la ventana de los markers
      mapCenter: { lat:19.4402725, lng:-99.103795 }, // Centro del mapa
      gmapInfo: { // Obj para la ventana de los markers
        lat: 0, 
        lng: 0,
        content: ``
      },

      displayAs: 'map', // map & list
      scrollY: 0,   // Scroll Y al bajar/subir (vertical)
      positionY: 0, // Posición Y para un elemento (vertical)

      viewportW: 0, // Anchura de *La ventana*
      viewportH: 0, // Altura de *La ventana*
      headerH: 0,   // Altura del *Header*
      searchBarH: 0,// Altura de *La barra de búsqueda*
      mapH: 0,      // Altura del *Mapa*
      // == ==

      markers: [
        { id: 5, price: '822.00', name: 'Margarita valley', position: { lat: 20.6696524, lng: -103.3638994 }, content: `A very very short description` },
        { id: 6, price: '700.00', name: 'Villa de los angeles', position: { lat: 19.317365, lng: -99.2211063 }, content: `A very very short description` },
      ],

      properties: [
       /* { imgUrl: 'public/images/pages/properties/property-1.jpg', name: 'Margarita valley' },
        { imgUrl: 'public/images/pages/properties/property-4.jpg', name: 'Mansion Central' },*/
      ],

      form: {
        order: 1,
      },
      pagination:{
        currentpage:1,
        total:1,
        number: 10,
        total_properties:1,
      },
    }
  },

  methods: {
    handleScroll(event){
      this.scrollY = window.scrollY;
      // console.log(this.scrollY);
      this.viewportW = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
      this.viewportH = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
      this.headerH = document.getElementById( 'header' ).offsetHeight;
      this.searchBarH = document.getElementById( 'search-bar' ).offsetHeight;
      let tempMapH = this.viewportH - (this.headerH + this.searchBarH);

      if(this.viewportW >= 992 && this.displayAs == 'map') { // El mapa solo se mostrará en escritorio y si está seleccionado "map"
        if(this.scrollY < this.searchBarH){ // Si el scroll-Y es menor a la altura del Buscador (el Buscador siempre debe estar visible y nunca ser tapado por el mapa)
          this.positionY = (this.headerH + this.searchBarH) - this.scrollY;
          this.mapH = tempMapH + scrollY;
        }else {
          this.positionY = this.headerH;
          this.mapH = this.viewportH - this.headerH;
        }

        document.getElementById('box-map-container').setAttribute('style','top:'+this.positionY+'px;height:'+this.mapH+'px;');

        // console.log('el viewportW es', this.viewportW);
        // console.log('el viewportH es', this.viewportH);
        // console.log('el headerH es', this.headerH);
        // console.log('el searchBarH es', this.searchBarH);
        // console.log('el tempMapH es', tempMapH);
        // console.log('el mapH es', this.mapH);
      }else {
        this.displayAs = 'list';
      }
    },

    markAction(val){
      // console.log(this);
      this.gmapInfo = val;
      this.windowOpen = true;
    },

    getRouteParams() {
      this.$root.modal_loading = true;
      this.$root.searchForm.order_price = this.form.order;
      
      /*this.$root.searchForm.buyRent = this.$route.query.buyrent;
      this.$root.searchForm.destination = this.$route.query.destination;
      this.$root.searchForm.propType = this.$route.query.propType;
      this.$root.searchForm.minprice = this.$route.query.minprice;
      this.$root.searchForm.maxprice = this.$route.query.maxprice;
      this.$root.searchForm.minacreage = this.$route.query.minacreage;
      this.$root.searchForm.maxminacreage = this.$route.query.maxminacreage;
      this.$root.searchForm.lifestyle = this.$route.query.lifestyle;*/


      axios.post(tools.url("/api/properties?page=" + this.pagination.currentpage),this.$root.searchForm).then((response)=>{
        this.properties = response.data.data;
        this.pagination.total = response.data.last_page;
        this.pagination.total_properties = response.data.total;
        this.$root.modal_loading = false;
      });

      setTimeout(()=> {
        this.showMap = true;
      }, 1000);

    },

    getProperties() { // Obtener la info de la BD
      this.showMap = false;
      this.getRouteParams(); // Antes, obtener la info de la ruta

      console.log('Searching...');
    },

    formatNum(num) {
      let x = Intl.NumberFormat("en-US").format(num);
      return x;
    },

    linkGen(pageNum) {
         return pageNum === 1 ? '?' : `?page=${pageNum}`
    },
  },

  created () {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleScroll);
  },

  watch: {
    displayAs(newVal, oldVal) {
      if(newVal == 'map') {
        setTimeout(()=>{
          this.handleScroll();
        }, 300);
      }
    },

    '$route' (to, from){

      if(this.$route.query.page){
        this.pagination.currentpage = this.$route.query.page;
        }
        else{
            this.pagination.currentpage = 1;
        }
        this.getProperties();
    },

    'form.order':function(val){
        /*if (this.form.order == 1) {
          var sortedproperties = this.properties.sort(
            (p1, p2) => (p1.price > p2.price) ? 1 : (p1.price < p2.price) ? -1 : 0);
        }
        else if(this.form.order == 2){
            var sortedproperties = this.properties.sort(
            (p1, p2) => (p1.price < p2.price) ? 1 : (p1.price > p2.price) ? -1 : 0);
        }
        this.properties = sortedproperties;*/
        this.getRouteParams();
    },
    /*'$route.query.page':function(val){
        if (val) {
            this.pagination.currentpage = val;
        }
        else{
            this.pagination.currentpage = 1;
        }
        this.getRouteParams();

    },*/
  },

  mounted(){
    setTimeout(()=>{
      this.handleScroll();
    }, 600);

    this.getProperties();
  },

  destroyed () {
    window.removeEventListener('scroll', this.$root.handleScroll);
    window.removeEventListener('resize', this.$root.handleScroll);
  }
}
</script>
