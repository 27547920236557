const messages = {
    en: {
        shared: {
        },
        header:{
            nav:{
                properties:'Properties',
                    properties_for_sale:'Sale',
                    properties_for_rent:'Rent',
                discover:'Discover',
                    destinations:'Destinations',
                    lifestyles:'Lifestyles',
                    projects:'Projects',
                    blog:'Our blog',

                about_us:'About us',
                    sell_with_us:'Sell my property',
                    about_sothebys_mexico:'About us',
                agents:'Agents',
                contact_us:'Contact us',
            },
            menu_preferencers:{
                preferencers:'PREFERENCES',
                language:'LANGUAGE',
                    en:'English - United States',
                    es:'Spanish - México',
                currency:'CURRENCY',
                unit_measure:'UNITS OF MEASURE',
            },
        },
        footer:{
            properties:'Properties',
            all_listing:'All listing',
            reting:'Renting',
            selling:'Selling',

            discover:'DISCOVER',
            destinations:'Destinations',
            lifestyle:'Lifestyle',
            projects:'Projects',

            about_us:'ABOUT US',
            selling_a_home:'Selling a home',
            about_sothebys_mexico:'About us',

            more_links:'MORE LINKS',
            agents:'Agents',
            contact_us:'Contact us',
            our_blog:'Our Blog',
        },
        home:{
            filter:{
                what_are_you:'WHAT ARE YOU',
                looking:'SEARCHING',
                location:'Location',
                property_type:'Property type',
                search_by:'Search by',
                keywords:'Keywords',
                search_btn:'SEARCH',
                any_option:'ANY',

                for_sale:'For sale',
                for_rent:'For rent',
                filter_by:'Filter by',
                listing_type:'LISTING TYPE',
                price:'PRICE',
                property_type_filter:'PROPERTY TYPE',
                lifestyle:'LIFESTYLES', 
            },
            exclusive_section:{
                our_most_exclusive:'Featured properties',
                our_exclusive:'OUR EXCLUSIVES',
                most_breathtaking:'We have the most impressive and unique properties in Riviera Maya and surroundings.',
                btn_viewall:'View property'
            },
            current_developments:{
                current_developments:'Real estate projects',
                our_latest:'OUR LATEST DEVELOPMENTS',
                discover:'Discover premium real estate developments that have partnered with us',
                btn_seemore:'SEE MORE'
            },
            our_locations:{
                our_locations:'Our locations',
                learn_more:'LEARN MORE ABOUT Riviera Maya FROM THE INSIDE OUT',
                btn_explore:'EXPLORE',
            },
            sell_your_property:{
                sell_your_property:'Sell your property',
                sell_your_property_w:'with us',
                let_guide:'Let us guide you through every step of the way, and present your property to an international audience of clients.',
                btn_sell:'SELL A PROPERTY',
            },
            events:{
                news:'News &',
                events:'Events',
                our_latest:'OUR LATEST NEWS',
                btn_read_more:'READ MORE'
            },
            statistics:{
                local_e:'LOCAL & INTERNATIONAL EXPERIENCE',
                total_listing:'Total listings',
                sold_volumne:'Sold volume',
                happy_customer:'Happy customers',
            },
            form_contact:{
                conect_with_us:'Connect with us',
                name:'Name(s)',
                last_name:'Last name',
                phone:'Phone number',
                email:'Email',
                comments:'Comments',
                submit:'SUBMIT',
            }

        },
        result:{
            title:'Properties for rent',
            subtitle:'OUR BEST PROPERTIES',
            title_description:'Discover the most extraordinary houses and apartments for rent in Riviera Maya. Let yourself be guided by the most exclusive real estate agency in Riviera Maya, and find your dream home.',
            
            properties:'Properties',
            subtitleproperties:'Showing listings marketed by all brokers in the search area.',
            sellaproperty:'Sell a property',
            properties_forsale:'for sale',
            properties_forrent:'for rent',

            filter:'Filter by',
            filter_pricelow:'Price Low to High',
            filter_pricehigh:'Price High to Low',
            filter_list:'List',
            filter_map:'Map',
            filter_propeties:'properties',

            result_bath:'Baths',
            result_bath_partial:'Partial',
            result_bed:'Bed',

            
        },
        destinations:{
            title:'Destinations',
            subtitle:'OUR BEST LOCATIONS',
            description:'Contact us so we can help you find your dream home in one of these locations.',
            detail_description:'Discover the most extraordinary houses and apartments for sale in Riviera Maya. Let yourself be guided by the most exclusive real estate agency in Riviera Maya, and find your dream home.',
        },
        lyfestyles:{
            title:'Lyfestyles',
            subtitle:'OUR BEST LOCATIONS',
            description:'Discover the most beautiful destinations and apartments for sale based on your ideal lifestyle.',
            detail_description:'Discover the most extraordinary houses and apartments for sale in Riviera Maya. Let yourself be guided by the most exclusive real estate agency in Riviera Maya, and find your dream home.',
        },
        projects:{
            title:'Projects',
            subtitle:'OUR BEST LOCATIONS',
            description:'Your best life begins with a home that inspires you.',

            detail:{
                download_brochure:'Download Brochure',
                masterplan:'Masterplan',
                gallery:'Gallery',
                location:'Location',
                contact_us:'Contact us!'
            },
            form:{
                name:'Names(s)',
                email:'Email',
                phone:'Phone number',
                wheredid:'Where did you hear about us?',
                bestdate:'Best date',
                arlternativedate:'Alternative date',
                horario:'Schedule',
                comentarios:'Comments',
                submit:'Submit',
            }
        },
        blog:{
            title:'Blog',
            subtitle:'OUR RECENT INFORMATION',
            readmore:'READ MORE',
            
            detail:{
                back:'Back to blog',
                by_sothebys:'By Sothebys'
            }
        },
        agents:{
            title:'Riviera Maya Sotheby’s International Realty Agents',
            subtitle:'OUR EXCLUSIVE EXPERTS',
            description:'Our local experts will guide you on your journe',

            searchbyname:'SEARCH BY NAME',
            agentsserving:'Agents serving Riviera Maya',
            results:'results',
            connect:'CONNECT WITH THE CLOSEST OFFICE',
            findyouroffice:'FIND YOUR OFFICE',
            associate:'Associate(s) in Riviera Maya',
            filterby:'Filter by',
            filterorder:'to',
            luxury:'Luxury Property Specialist',
            phone:'Number',
            email:'Email',
            office:"Riviera Maya SOTHEBY'S INTERNATIONAL REALTY OFFICE",
            sendmessage:'SEND A MESSAGE',
            detail:{
                luxury:'Luxury Property Specialist',
                sendmessage:'SEND A MESSAGE',
                aboutme:'About me',
                office:'Office',
                mobile:'Mobile',
                email:'Email',
                language:'language',
                mylisted:'My listed Properties',
                contactme:'Contact me',
                notpropeties:'There are not properties related to this agent',
            },
            form:{
                name:'Names(s)',
                lastname:'Lastsname',
                email:'Email',
                phone:'Phone number',
                comentarios:'Comments',
                submit:'Submit',
            }
        },
        contact:{
            title:'Contact us',
            subtitle:'WE WANT TO HEAR FROM YOU',
            description:'Need some help or have some questions?',
            description_two:' Contact us and we will help you as soon as we can.',
            thankyou:"THANK YOU FOR CONTACTING SOTHEBY'S INTERNATIONAL REALTY",

            info_one:'Using the form below, please provide as much detailed information as possible. The information you submit may be shared with our independently owned and operated franchisee so that your concerns can be addressed.',
            info_two:'Please note that by providing your phone number and email address, you are providing consent for us to reach out to you via this method.',
            info_three:'If this is a Data Privacy related matter, please click',
            here:'here',
            form:{
                name:'Names(s)',
                lastname:'Lastsname',
                email:'Email',
                phone:'Phone number',
                comments:'Comments',
                message:'Message',
                submit:'Submit',
            }
            
        },
        detail:{
            home:'Home',
            exclusivelist:'Exclusive list',
            bath:'BATHS',
            bath_partial:'PARTIAL',
            bed:'BED',
            description:'Description',
            sendmessage:'Send a message',
            propetydetails:'PROPERTY DETAILS',
            location:'Location',
            propertytype:'Property type',
            yearbulit:'Year built',
            about:'ABOUT',
            city:'Riviera Maya',
            bussiest:'The bussiest city in Riviera Maya and our of the most lively.',
            seemore:'SEE MORE',
            lastnews:'Latest news',
            citynews:'Riviera Maya latest news in Riviera Maya Sothebys International Realty',

            amenities_features:'Amenities & Features',
            listingdetails:'LISTING DETAILS',
            property_id:'Property ID',
            property_type:'Property type',
            marketed_by:'Marketed by',
            listing_price:'Listing price',
            utilities:'Utilities & Building',

            year_bulit:'Year Built',
            lot_interior:'Lot size Interior',
            lot_exterior:'Lot size Exterior',
            parking:'Parking',
            car_garage:'Car Garage',
            interior:'Interior',
            amenities:'Amenities',
            additonal_features:'Additional Features',

            schedule_visit:'SCHEDULE A VISIT',
            luxury:'Luxury Property Specialist',
            letsget:"Let's get in touch",
            continueyoursearch:'Continue your search',
            form:{
                fullname:'Full name',
                email:'Email',
                schedule_date:'Schedule date',
                select_time:'Select time',
                schedule:'SCHEDULE',

                names:'Name(s)',
                lastnames:'Last name',
                phone:'Phone number',
                comments:'Comments',

                sendmessage:'SEND MESSAGE'

            }

        }
    },
    es: {
        shared: {
        },
        header:{
            nav:{
                properties:'Propiedades',
                    properties_for_sale:'Venta',
                    properties_for_rent:'Renta',
                discover:'Descubrir',
                    destinations:'Destinos',
                    lifestyles:'Estilos de vida',
                    projects:'Proyectos',
                    blog:'Nuestro blog',
                about_us:'Sobre nosotros',
                    sell_with_us:'Vender mi propiedad',
                    about_sothebys_mexico:'Acerca de nosotros',
                agents:'Agentes',
                contact_us:'Contactanos',
                btn_aply:'Apply'
            },
            menu_preferencers:{
                preferencers:'PREFERENCIAS',
                language:'LENGUAJE',
                    en:'Ingles - Estados Unidos',
                    es:'Español - México',
                currency:'MONEDA',
                unit_measure:'UNIDAD DE MEDIDA',
                btn_aply:'Aplicar'
            }
        },
        footer:{
            properties:'Propiedades',
            all_listing:'Todos los listados',
            reting:'Renta',
            selling:'Venta',

            discover:'DESCUBRIR',
            destinations:'Destinos',
            lifestyle:'Estilo de vida',
            projects:'Proyectos',

            about_us:'SOBRE NOSOTROS',
            selling_a_home:'Vender una casa',
            about_sothebys_mexico:"Acerca de nosotros",

            more_links:'MÁS ENLACES',
            agents:'Agentes',
            contact_us:'Contactanos',
            our_blog:'Nuestro blog',
        },
        home:{
            filter:{
                what_are_you:'¿QUÉ ESTAS',
                looking:'BUSCANDO',
                location:'Ubicación',
                property_type:'Tipo de propiedad',
                search_by:'Búsqueda por',
                keywords:'Palabras clave',
                search_btn:'BUSCAR',
                any_option:'CUALQUIERA',

                for_sale:'En venta',
                for_rent:'En renta',
                filter_by:'Filtrado por',
                listing_type:'TIPO DE ANUNCIO',
                price:'PRECIO',
                property_type_filter:'TIPO DE PROPIEDAD',
                lifestyle:'ESTILOS DE VIDA', 
            },
            exclusive_section:{
                our_most_exclusive:'Propiedades destacadas',
                our_exclusive:'NUESTRAS EXCLUSIVAS',
                most_breathtaking:'Contamos con las propiedades más impresionantes y únicos de Riviera Maya y alrededores.',
                btn_viewall:'Ver propiedad'
            },
            current_developments:{
                current_developments:'Proyectos Inmobiliarios',
                our_latest:'NUESTROS ÚLTIMOS DESARROLLOS',
                discover:'Descubre desarrollos inmobiliarios premium que se han asociado con nosotros',
                btn_seemore:'VER MÁS'
            },
            our_locations:{
                our_locations:'Nuestras ubicaciones',
                learn_more:'CONOCE MÁS SOBRE LA Riviera Maya DESDE ADENTRO HACIA AFUERA',
                btn_explore:'EXPLORAR',
            },
            sell_your_property:{
                sell_your_property:'Vende tu propiedad',
                sell_your_property_w:'con nosotros',
                let_guide:'Permítanos guiarlo a través de cada paso del camino y presente su propiedad a una audiencia internacional de clientes',
                btn_sell:'VENDER UNA PROPIEDAD',
            },
            events:{
                news:'Noticias y',
                events:'Eventos',
                our_latest:'NUESTRAS ÚLTIMAS NOTICIAS',
                btn_read_more:'LEER MÁS'

            },
            statistics:{
                local_e:'EXPERIENCIA LOCAL E INTERNACIONAL',
                total_listing:'Listados totales',
                sold_volumne:'Volumen vendido',
                happy_customer:'Clientes felices',
            },
            form_contact:{
                conect_with_us:'Conectate con nosotros',
                name:'Nombre(s)',
                last_name:'Apellido',
                phone:'Telefono',
                email:'Correo',
                comments:'Comentarios',
                submit:'Enviar',
            }
        },
        result:{
            title:'Propiedades en alquiler',
            subtitle:'NUESTRAS MEJORES PROPIEDADES',
            title_description:'Descubre las más extraordinarias casas y departamentos en renta en la Riviera Maya. Déjate guiar por la agencia inmobiliaria más exclusiva de la Riviera Maya, y encuentra la casa de tus sueños.',
            
            properties:'Propiedades',
            subtitleproperties:'Mostrando listados comercializados por todos los corredores en el área de búsqueda.',
            sellaproperty:'Vender una propiedad',
            properties_forsale:'en venta',
            properties_forrent:'en renta',


            filter:'Filtrado por',
            filter_pricelow:'Precio bajo a alto',
            filter_pricehigh:'Precio alto a bajo',
            filter_list:'Lista',
            filter_map:'Mapa',
            filter_propeties:'propiedades',

            result_bath:'Baños',
            result_bath_partial:'Parcial',
            result_bed:'Recamaras',
        },
        destinations:{
            title:'Destinos',
            subtitle:'NUESTRAS MEJORES UBICACIONES',
            description:'Contáctenos para que podamos ayudarlo a encontrar la casa de sus sueños en una de estas ubicaciones.',
            detail_description:'Descubre las casas y departamentos más extraordinarios en venta en la Riviera Maya. Déjate guiar por la agencia inmobiliaria más exclusiva de la Riviera Maya, y encuentra la casa de tus sueños.',
        },
        lyfestyles:{
            title:'Estilos de vida',
            subtitle:'NUESTRAS MEJORES UBICACIONES',
            description:'Descubra los destinos y apartamentos en venta más hermosos según su estilo de vida ideal.',
            detail_description:'Descubre las casas y departamentos más extraordinarios en venta en la Riviera Maya. Déjate guiar por la agencia inmobiliaria más exclusiva de la Riviera Maya, y encuentra la casa de tus sueños.',
        },
        projects:{
            title:'Proyectos',
            subtitle:'NUESTRAS MEJORES UBICACIONES',
            description:'Tu mejor vida comienza con un hogar que te inspire.',
            detail:{
                download_brochure:'Descargar Brochure',
                masterplan:'Plan maestro',
                gallery:'Galería',
                location:'Ubicación',
                contact_us:'Contactanos!'
            },
            form:{
                name:'Nombre(s)',
                email:'Correo',
                phone:'Telefono',
                wheredid:'¿Dónde escuchaste acerca de nosotros?',
                bestdate:'Mejor fecha',
                arlternativedate:'Fecha alternativa',
                horario:'Horario',
                comentarios:'Comentarios',
                submit:'Enviar',
            }
        },
        blog:{
            title:'Blog',
            subtitle:'NUESTRA INFORMACIÓN RECIENTE',
            readmore:'LEER MÁS',

            detail:{
                back:'Volver al blog',
                by_sothebys:'Por Sothebys'
            }
        },
        agents:{
            title:'Riviera Maya Sotheby’s International Realty Agents',
            subtitle:'NUESTROS EXPERTOS EXCLUSIVOS',
            description:'Nuestros expertos locales te guiarán en tu viaje',

            searchbyname:'BUSCAR POR NOMBRE',
            agentsserving:'Agentes que atienden en Riviera Maya',
            results:'resultados',
            connect:'CONÉCTATE CON LA OFICINA MÁS CERCANA',
            findyouroffice:'ENCUENTRA TU OFICINA',
            associate:'Asociado (s) en Riviera Maya',
            filterby:'Filtrado por',
            filterorder:'a',
            luxury:'Especialista en propiedades de lujo',
            phone:'Teléfono',
            email:'Correo',
            office:"Riviera Maya SOTHEBY'S INTERNATIONAL REALTY OFFICE",
            sendmessage:'ENVIAR UN MENSAJE',

           detail:{
                luxury:'Especialista en propiedades de lujo',
                sendmessage:'ENVIAR UN MENSAJE',
                aboutme:'Acerca de mí',
                office:'Oficina',
                mobile:'Teléfono',
                email:'Correo',
                language:'Idiomas',
                mylisted:'Mis propiedades listadas',
                contactme:'Contáctame',
                notpropeties:'No hay propiedades relacionadas con este agente',
                
            },
            form:{
                name:'Nombre(s)',
                lastname:'Apellidos',
                email:'Correo',
                phone:'Telefono',
                comentarios:'Comentarios',
                submit:'Enviar',
            }
        },
        contact:{
            title:'Cotactanos',
            subtitle:'Nosotros queremos escuchar de ti',
            description:'¿Necesita ayuda o tiene algunas preguntas?',
            description_two:'Ponte en contacto con nosotros y te ayudaremos lo antes posible.',
            thankyou:"GRACIAS POR CONTACTARSE CON SOTHEBY'S INTERNATIONAL REALTY",

            info_one:'Mediante el siguiente formulario, proporcione la mayor cantidad de información detallada posible. La información que envíe se puede compartir con nuestro franquiciado de propiedad y operación independiente para que se puedan abordar sus inquietudes.',
            info_two:'Tenga en cuenta que al proporcionar su número de teléfono y dirección de correo electrónico, está dando su consentimiento para que nos comuniquemos con usted a través de este método.',
            info_three:'Si se trata de un asunto relacionado con la privacidad de datos, haga clic en',
            here:'aqui',
            form:{
                name:'Nombre(s)',
                lastname:'Apellidos',
                email:'Correo electronico',
                phone:'Telefono',
                comentarios:'Comentarios',
                message:'Mensajes',
                submit:'Enviar',
            }
            
        },
        detail:{
            home:'Incio',
            exclusivelist:'Lista exclusiva',
            bath:'BAÑOS',
            bath_partial:'PARCIAL',
            bed:'RECAMARA',
            description:'Descripción',
            sendmessage:'Enviar un mensaje',
            propetydetails:'DETALLES DE LA PROPIEDAD',
            location:'Ubicación',
            propertytype:'Tipo de propiedad',
            yearbulit:'Año de construcción',
            about:'ACERCA DE',
            city:'Riviera Maya',
            bussiest:'La ciudad más concurrida de Riviera Maya y fuera de las más animadas.',
            seemore:'VER MÁS',
            lastnews:'Últimas noticias',
            citynews:'Últimas noticias en Riviera Maya Sothebys International Realty',

            amenities_features:'Servicios y características',
            listingdetails:'LISTADO DE DETALLES',
            property_id:'Identificación de propiedad',
            property_type:'Tipo de propiedad',
            marketed_by:'Comercializado por',
            listing_price:'Precio de lista',
            utilities:'Servicios públicos y construcción',
            
            
            lot_interior:'Tamaño del lote Interior',
            lot_exterior:'Tamaño del lote Exterior',
            parking:'Estacionamiento',
            car_garage:'Garaje',
            interior:'Interior',
            amenities:'Comodidades',
            additonal_features:'Características adicionales',

            schedule_visit:'PROGRAMAR UNA VISITA',
            luxury:'Especialista en propiedades de lujo',
            letsget:"Mantengámonos en contacto",
            
            continueyoursearch:'Continúa tu búsqueda',
            form:{
                fullname:'Nombre completo',
                email:'Correo electrónico',
                schedule_date:'Fecha de programacion',
                select_time:'Seleccionar hora',
                schedule:'PROGRAMAR',

                names:'Nombre(s)',
                lastnames:'Apellidos',
                phone:'Teléfono',
                comments:'Comentarios',

                sendmessage:'ENVIAR MENSAJE'

            }
        }
    },
}
export default messages;
