<template lang="html">
  <div id="property-detail-page">

    <section class="banner-section">
      <swiper class="swiper" :options="galleryOptions" v-if="showGallery">
        <swiper-slide v-for="(image, mInx) in property.images" :key="mInx">
          <div class="placed-backg box-content" v-bind:style="{ backgroundImage: 'url(' + image.url +')' }">
            <img src="public/images/pages/properties/img-gallery.png">

            <div class="box-info">
              <div class="container oversized-container">
                <div class="row">
                  <div class="col-12 align-self-start col-breadcrumb">
                    <router-link to="/">{{ $t("detail.home") }}</router-link>
                    <span>{{ $t("detail.exclusivelist") }}</span>
                    <span class="f-w-600">{{property.name}}</span>
                  </div>

                  <div class="col-12 align-self-end col-bottom">
                    <div class="row">
                      <div class="col-lg-6 col-lx-5">
                        <h2 class="name">{{property.name}}</h2>
                      </div>

                      <div class="col-lg">

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>

        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>

      <img class="w-100" src="public/images/pages/properties/img-gallery.png" v-else>
    </section>

    <section class="micro-info-section">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-lg-8 col-name">
            <h6>{{property.name}}</h6>
          </div>

          <div class="col-lg-4 col-buttons">

          </div>
        </div>
      </div>
    </section>

    <section class="info-section">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-lg-8 col-left">
            <div class="row align-items-center">
              <div class="col-12 col-name">
                <h1 class="name">{{property.name}}</h1>
              </div>

              <div class="col-lg col-price">
                <h3 class="price" style="font-size: 30px;" v-if="$root.currency == 'usd'">$ {{ formatNum(property.price_usd) }} USD</h3>
                <h3 class="price" style="font-size: 30px;" v-else-if="$root.currency == 'mxn'">$ {{ formatNum(property.price_mxn) }} <span v-if="property.price == 0 || property.price == null"> USD </span> <span v-else> MXN</span></h3>
              </div>

              <div class="col-lg col-prop-info">
                <div class="box-info">
                  <div class="icon">
                    <img src="public/images/pages/properties/ic-ft.png">
                  </div>

                  <div class="text" v-if="$root.unit == 'metric'">{{ formatNum(property.size_interior) }} {{ $i18n.locale == 'en' ? 'Square meters'  : 'Metros cuadrados' }}</div>
                  <div class="text" v-else-if="$root.unit == 'us'">{{ formatNum(property.size_interior_ft) }} {{ $i18n.locale == 'en' ? 'Square feet'  : 'Pies cuadrados' }}</div>
                </div>

                <div class="box-info">
                  <div class="icon">
                    <img src="public/images/pages/properties/ic-total.png">
                  </div>

                  <div class="text" v-if="$root.unit == 'metric'">{{ formatNum(property.size) }} {{ $i18n.locale == 'en' ? 'Square meters'  : 'Metros cuadrados' }}</div>

                  <div class="text" v-else-if="$root.unit == 'us'">{{ formatNum(property.size_ft) }} {{ $i18n.locale == 'en' ? 'Square feet'  : 'Pies cuadrados' }}</div>
                </div>

                <div class="box-info">
                  <div class="icon">
                    <img src="public/images/pages/properties/ic-bed.png">
                  </div>

                  <div class="text">{{property.bedrooms}} {{ $t("detail.bed") }}</div>
                </div>

                <div class="box-info">
                  <div class="icon">
                    <img src="public/images/pages/properties/ic-bathroom.png">
                  </div>

                  <div class="text">{{property.toilets}} {{ $t("detail.bath") }} <br><span v-if="property.toilets_partial != null && property.toilets_partial != 0">{{property.toilets_partial}} {{ $t("detail.bath_partial") }}</span></div>
                </div>
              </div>
            </div>

            <div class="row align-items-center box-social-networks" style="font-size: 25px;">
              <social-sharing
                      :url="sharing.url + id"
                      :title="sharing.title"
                      :description="property.name"
                      :quote="property.name"
                      :hashtags="sharing.title"
                      inline-template>
                      <div style="font-size: 30px;">
                          <network network="email" style="padding-left: 20px;cursor: pointer;"><i class="fa fa-envelope"></i></network>
                          <network network="facebook" style="padding-left: 20px;cursor: pointer;"><i class="fab fa-facebook-square"></i></network>
                          <!-- <network network="twitter" style="padding-left: 20px;cursor: pointer;"><i class="fab fa-twitter-square"></i></network> -->
                          <network network="whatsapp" style="padding-left: 20px;cursor: pointer;">  <i class="fab fa-whatsapp-square"></i></network>
                      </div>
                  </social-sharing>

              <div class="btn-q btn-print" style="padding-left: 20px; cursor: pointer;" @click="getPdf"><span><i class="fas fa-print"></i></span></div>

              <div class="col-12 col-line"><hr /></div>
            </div>

            <div class="row">
              <div class="col-12 col-video" v-if="(property.video_url != null)">
                <div class="box-video">
                  <img src="public/images/pages/properties/video.png">
                  <iframe :src="property.video_url" title="Sothebys" width="100%" height="500" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>

              <div class="col-12 col-description">
                <h5 class="mb-2 title">{{ $t("detail.description") }}</h5>

                <!-- <iframe v-if="(property.video_url != null)" width="100%" height="500" :src="property.video_url" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
                ></iframe>
                <br><br> -->
                <div class="box-descr" v-html="property.description_es" v-if="$i18n.locale == 'es'"></div>
                <div class="box-descr" v-html="property.description" v-if="$i18n.locale == 'en'"></div>
               
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-right">
            <div class="row">
              <div class="col-12 col-gallery">
                <a class="t-150 btn-gallery" @click="setIndex(0)">{{property.images.length}} <i class="far fa-image"></i></a>

                <CoolLightBox
                  :items="gallery"
                  :index="index"
                  :effect="'fade'"
                  :useZoomBar="true"
                  loop
                  @close="index = null">
                </CoolLightBox>

                <div class="images-wrapper">
                  <div
                    class="image"
                    v-for="(image, imageIndex) in gallery"
                    :key="imageIndex"
                    @click="setIndex(imageIndex)"
                    :style="{ backgroundImage: 'url(' + image.src + ')' }">
                  </div>
                </div>
              </div>

              <div class="col-12 col-agent" v-if="(property.agents.length > 0)">
                <a class="btn-agent" href="#agent-sec" v-for="(agent,indx) in property.agents" :key="indx">
                  <div class="col placed-backg col-photo" v-bind:style="{ backgroundImage: 'url('+agent.imageUrl+')' }">
                  </div>

                  <div class="col col-info">
                    <h5>{{agent.name}}</h5>

                    <span>{{ $t("detail.sendmessage") }}</span>
                  </div>
                </a>
              </div>

              <div class="col-12 col-prop-short-info">
                <a class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url('+ gallery[0]+')' }" style="position: relative;" v-if="property.url_virtual != null" :href="property.url_virtual" target="_blank">
                  <div style="position: absolute;top: 50%;left: 50%;height: 30%;width: 50%;margin: -15% 0 0 -25%;text-align: center;font-size: 100px;" >
                    <i class="fas fa-street-view" style="color:white"></i>
                  </div>
                  <img src="public/images/pages/properties/property.png">
                </a>
                <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url('+ gallery[0]+')' }" style="position: relative;" v-else>
                  <div style="position: absolute;top: 50%;left: 50%;height: 30%;width: 50%;margin: -15% 0 0 -25%;text-align: center;font-size: 100px;" >

                  </div>
                  <img src="public/images/pages/properties/property.png">
                </div>


                <h4 class="mb-3 title-ps-1"><span>{{ $t("detail.propetydetails") }}</span></h4>

                <div class="box-short-info">
                  <b-form-group class="mb-1 custom-f-group-1"
                    :label='$t("detail.location")' v-if="property.formattedAddress != null">
                    <div class="form-control">
                      <span>{{property.formattedAddress}}</span>
                    </div>
                  </b-form-group>

                  <b-form-group class="mb-1 custom-f-group-1"
                    :label='$t("detail.location")' v-else>
                    <div class="form-control">
                      <span v-if="property.address">{{property.address}}</span> <span v-if="property.town">, {{property.town}}</span> <span v-if="property.state">, {{property.state}}</span>
                    </div>
                  </b-form-group>
                  <br>
                  <b-form-group class="mb-1 custom-f-group-1"
                    :label='$t("detail.propertytype")' v-if="property.type != null">
                    <div class="form-control">
                      {{property.type}}
                    </div>
                  </b-form-group>
                  <br>
                  <!-- <b-form-group class="mb-1 custom-f-group-1"
                    label="Status">
                    <div class="form-control">
                      {{property.status}}
                    </div>
                  </b-form-group> -->

                  <b-form-group class="mb-1 custom-f-group-1"
                    :label='$t("detail.yearbulit")' v-if="property.year_built != null">
                    <div class="form-control">
                      {{property.year_built}}
                    </div>
                  </b-form-group>

                  <!-- <b-form-group class="mb-1 custom-f-group-1"
                    label="Property taxes" v-if="property.taxes != null">
                    <div class="form-control">
                      {{property.taxes}}
                    </div>
                  </b-form-group> -->
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="map-section">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-lg-7 col-map">
            <div class="box-map">
              <GmapMap
                :center="{lat: property.lat, lng: property.lng}"
                :zoom="15">
                <GmapMarker
                  :position="{lat: property.lat, lng: property.lng}"
                  :title="property.name"
                  :clickable="true"
                  :draggable="false"
                  :icon="{ url: 'public/images/shared/map-icon.svg'}"
                />
              </GmapMap>
            </div>
          </div>

          <div class="col-lg-5 col-text">
            <h2 class="mb-2 title">{{ $t("detail.about") }} <strong>{{ $t("detail.city") }}</strong></h2>

            <p>{{ $t("detail.bussiest") }}</p>

            <p class="mt-3">
              <router-link class="btn btn-s1 white btn-outline" :to="'/office/'+property.officeId">{{ $t("detail.seemore") }}</router-link>
            </p>

            <hr />

            <h6 class="subtitle">{{ $t("detail.lastnews") }}</h6>
            <p>
              <router-link to="/blog">{{ $t("detail.citynews") }}</router-link>
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="amenities-section">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-lg-8 col-info">
            <h2 class="mb-4 text-center title">{{ $t("detail.amenities_features") }}</h2>

            <div class="row">
              <div class="col-sm-6 col-xl-4 my-1">
                <h6 class="mb-2 subtitle">{{ $t("detail.listingdetails") }}</h6>

                <b-form-group class="mb-1 custom-f-group-1"
                  :label='$t("detail.property_id")' v-if="property.id_property != null">
                  <div class="form-control sm">
                    {{property.rfg_id}}
                  </div>
                </b-form-group>

                <b-form-group class="mb-1 custom-f-group-1"
                :label='$t("detail.property_type")' v-if="property.type != null">
                  <div class="form-control sm">
                    {{property.type}}
                  </div>
                </b-form-group>

                <b-form-group class="mb-1 custom-f-group-1"
                :label='$t("detail.marketed_by")' v-if="property.marketed_by != null">
                  <div class="form-control sm">
                    {{property.marketed_by}}
                  </div>
                </b-form-group>

                <!-- <b-form-group class="mb-1 custom-f-group-1"
                  label="Status">
                  <div class="form-control sm">
                    {{property.status}}
                  </div>
                </b-form-group> -->

                <b-form-group class="mb-1 custom-f-group-1"
                :label='$t("detail.listing_price")' v-if="property.price != null">
                  <div class="form-control sm" v-if="$root.currency == 'usd'">
                    $ {{ formatNum(property.price_usd) }} USD
                  </div>
                  <div class="form-control sm" v-else-if="$root.currency == 'mxn'">
                    $ {{ formatNum(property.price_mxn) }} <span v-if="property.price == 0 || property.price == null"> USD </span> <span v-else> MXN</span>
                  </div>

                </b-form-group>
              </div>

              <div class="col-sm-6 col-xl-4 my-1">
                <h6 class="mb-2 subtitle">{{ $t("detail.utilities") }}</h6>

                <b-form-group class="mb-1 custom-f-group-1"
                :label='$t("detail.yearbulit")' v-if="property.year_built != null">
                  <div class="form-control sm">
                    {{property.year_built}}
                  </div>
                </b-form-group>

                <!-- <b-form-group class="mb-1 custom-f-group-1"
                  label="Property taxes">
                  <div class="form-control sm">
                    {{property.taxes}}
                  </div>
                </b-form-group> -->

               <b-form-group class="mb-1 custom-f-group-1"
               :label='$t("detail.lot_interior")' v-if="property.size_interior != null">
                  <div class="form-control sm">
                    {{property.size_interior}}
                  </div>
                </b-form-group>

                <b-form-group class="mb-1 custom-f-group-1"
                  :label='$t("detail.lot_exterior")' v-if="property.size_exterior != null">
                  <div class="form-control sm">
                    {{property.size_exterior}}
                  </div>
                </b-form-group>

                <!-- <b-form-group class="mb-1 custom-f-group-1"
                  label="Lot size">
                  <div class="form-control sm">
                    {{property.size_interior}} / {{property.size_exterior}}
                  </div>
                </b-form-group> -->

                <b-form-group class="mb-1 custom-f-group-1"
                :label='$t("detail.parking")' v-if="property.parking_lots != null">
                  <div class="form-control sm">
                    {{property.parking_lots}}+ {{ $t("detail.car_garage") }}
                  </div>
                </b-form-group>
              </div>

              <div class="col-xl-4 my-1" >
                <h6 class="mb-2 subtitle">{{ $t("detail.interior") }}</h6>

                <b-form-group class="mb-1 custom-f-group-1 group-text"
                :label='$t("detail.amenities")' v-if="property.amenitiesarray != null">
                  <div class="form-control sm">
                    <ul>
                      <li style="padding-top: 10px;" v-for="(a,aindx) in property.amenitiesarray" :key="aindx">{{ a }}</li>
                    </ul>

                  </div>
                </b-form-group>

                <b-form-group class="mb-1 custom-f-group-1 group-text"
                :label='$t("detail.additonal_features")' v-if="property.additional_features != null">
                  <div class="form-control sm">
                    {{property.additional_features}}
                  </div>
                </b-form-group>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-xl-4 col-form">
            <b-form class="box-form" @submit="onSubmitSchedule">
              <h6 class="mb-2 text-center title">{{ $t("detail.schedule_visit") }}</h6>

              <b-form-group class="custom-f-group-2"
                :label='$t("detail.form.fullname")'>
                <b-form-input
                  v-model="formSchedule.name"
                  type="text"
                  size="lg"
                  placeholder=""
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group class="custom-f-group-2"
              :label='$t("detail.form.email")'>
                <b-form-input
                  v-model="formSchedule.email"
                  type="email"
                  size="lg"
                  placeholder=""
                  required
                ></b-form-input>
              </b-form-group>

              <div class="row">
                <div class="col-lg-6">
                  <b-form-group class="custom-f-group-2"
                  :label='$t("detail.form.schedule_date")'>
                    <b-form-datepicker v-model="formSchedule.date" :min="minDate" :date-disabled-fn="dateDisabled" placeholder="" :date-format-options="dateFormOpts" required></b-form-datepicker>
                  </b-form-group>
                </div>

                <div class="col-lg-6">
                  <b-form-group class="custom-f-group-2"
                  :label='$t("detail.form.select_time")'>
                    <b-form-timepicker v-model="formSchedule.time" locale="en" minutes-step="30" placeholder="" required></b-form-timepicker>
                  </b-form-group>
                </div>
              </div>

              <p class="mt-2 text-center">
                <b-button type="submit" class="btn-s1 blue btn-bg">{{ $t("detail.form.schedule") }}</b-button>
              </p>
            </b-form>
          </div>
        </div>
      </div>
    </section>

    <section class="agent-section" id="agent-sec" v-if="(property.agents.length > 0)">
      <div class="container oversized-container">
        <div class="row ali">
          <div class="col-lg-7 col-xl-5 my-2 col-agent-info" v-for="(agent,indx) in property.agents" :key="indx">
            <div class="col col-photo">
              <router-link :to="'/agents/'+agent.id">
                <div class="placed-backg photo" v-bind:style="{ backgroundImage: 'url('+ agent.imageUrl +')' }"></div>
              </router-link>

            </div>

            <div class="col-12 col-sm col-info">
              <router-link :to="'/agents/'+agent.id">
                <h3 class="mb-3 name">{{agent.name}}</h3>
              </router-link>
              <!-- <h5 class="mb-4 job">{{agent.profession}}</h5> -->
              <h5 class="mb-4 job">{{ $t("detail.luxury") }}</h5>
              
              <p class="mb-2 contact">
                {{ $t("detail.form.phone") }}: {{agent.phone}}
              </p>
              <p class="contact">
                <!-- Email: <a class="txt-email" :href="'mailto:'+agent.email">{{agent.email}}</a> -->
                {{ $t("detail.form.email") }}: <a class="txt-email" :href="'mailto:info@cdmxsir.com'">info@cdmxsir.com</a>

                
              </p>

              <div class="d-block">
                <hr class="mx-0" />
              </div>

              <h6 class="mb-1 subtitle">MEXICO SOTHEBY'S INTERNATIONAL REALTY </h6>

              <p class="address">
                 {{agent.address}}
              </p>
            </div>
          </div>

          <div class="col-12" v-if="property.agents.length > 1"></div>

          <div class="col-lg-7 my-2 col-form">
            <h6 class="mb-2 title">  {{ $t("detail.letsget") }}</h6>

            <b-form class="row" @submit="onSubmitAgent">
              <div class="col-lg-6">
                <b-form-group class="custom-f-group-2"
                :label='$t("detail.form.names")'>
                  <b-form-input
                    v-model="formAgent.name"
                    type="text"
                    size="lg"
                    placeholder=""
                    required
                  ></b-form-input>
                </b-form-group>
              </div>

              <div class="col-lg-6">
                <b-form-group class="custom-f-group-2"
                :label='$t("detail.form.lastnames")'>
                  <b-form-input
                    v-model="formAgent.lastname"
                    type="text"
                    size="lg"
                    placeholder=""
                    required
                  ></b-form-input>
                </b-form-group>
              </div>

              <div class="col-lg-6">
                <b-form-group class="custom-f-group-2"
                :label='$t("detail.form.phone")'>
                  <b-form-input
                    v-model="formAgent.phone"
                    type="number"
                    size="lg"
                    placeholder=""
                    required
                  ></b-form-input>
                </b-form-group>
              </div>

              <div class="col-lg-6">
                <b-form-group class="custom-f-group-2"
                :label='$t("detail.form.email")'>
                  <b-form-input
                    v-model="formAgent.email"
                    type="email"
                    size="lg"
                    placeholder=""
                    required
                  ></b-form-input>
                </b-form-group>
              </div>

              <div class="col-lg-12 mb-2">
                <b-form-group class="custom-f-group-2"
                :label='$t("detail.form.comments")'>
                  <b-form-textarea
                    v-model="formAgent.msg"
                    placeholder=""
                    rows="2"
                    max-rows="2"
                  ></b-form-textarea>
                </b-form-group>
              </div>

              <div class="col-md-7 col-captcha">
                <vue-recaptcha
                  sitekey="6LcaE4okAAAAAFYKGFfWc9wbF0IXRcRBzSzZmiyw"
                  ref="recaptcha"
                  @verify="handleSuccess"
                  >
                </vue-recaptcha>
              </div>
              <!-- <div class="g-recaptcha" data-sitekey="" id="recaptcha"></div> -->

              <div class="col-md-5 col-button">
                <b-button type="submit" class="btn-s1 blue btn-bg">{{ $t("detail.form.sendmessage") }}</b-button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
      <sweet-modal :icon="modal.icon" :blocking="modal.block" :hide-close-button="modal.block"  ref="modal">
        <div class="fa-3x" v-if="modal.icon== ''"><i class="fas fa-spinner fa-pulse"></i></div><br/>
        <div v-html="modal.msg"></div>

      </sweet-modal>
    </section>

    <section class="properties-section">
      <div class="container oversized-container">
        <h2 class="title-section">{{ $t("detail.continueyoursearch") }}</h2>

        <swiper class="swiper" :options="propertiesOptions">
          <swiper-slide v-for="(prop, prInx) in property.properties" :key="'prInx-'+prInx">
            <div class="col-12 px-0 col-property-sample-1">
              <router-link class="box-link" :to="'/property/'+prop.id">
                <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url('+prop.imageUrl+')' }">
                  <img src="public/images/pages/properties/property.png">
                </div>

                <div class="box-text">
                  <h5 class="name">{{ prop.name }}</h5>

                  <h6 class="price" v-if="$root.currency == 'usd'">$ {{ formatNum(prop.price_usd) }} USD</h6>
                    <h6 class="price" v-else-if="$root.currency == 'mxn'">$ {{ formatNum(prop.price_mxn) }}<span v-if="prop.price == 0 || prop.price == null"> USD </span> <span v-else> MXN</span></h6>


                  <div class="box-prop-info">
                    <!-- <span>SIZE: {{ prop.meters_ground}} FT</span> -->
                    <span style="padding-right: 0px">{{ prop.toilets}} {{ $t("result.result_bath") }}</span> <span style="padding-right: 0px" v-if="prop.toilets_partial != null && prop.toilets_partial != 0">, {{prop.toilets_partial}} {{ $t("result.result_bath_partial") }}</span>

                    <span>{{ prop.bedrooms}} {{ $t("result.result_bed") }}</span>
                    <!-- <span>{{ prop.size_interior}} </span>
                    <span>{{ prop.size_extrerio}} </span> -->
                  </div>
                </div>
              </router-link>
            </div>
          </swiper-slide>

          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </swiper>
      </div>
    </section>

  </div>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox'; // https://vuejsexamples.com/vue-js-lightbox-package-without-any-dependencies/
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';

export default {

  components: {
    CoolLightBox,
  },

  data() {
    return {
      showGallery: false,
      gallery: [],
      index: null,

      property: {
        name: 'Villa de los Angeles',
        position: { lat: 20.6696524, lng: -103.3638994 },
        agent:{
          imageUrl:null,
        },
        images:[
          //{url:'https://sothebystest.club/rm/public/images/logo-380.png'}
        ],
        agents:[],
        properties:[],
        lat:0,
        lng:0
      },

      formSchedule: {
        name: null,
        email: null,
        date: null,
        time: null,
      },

      formAgent: {
        name: null,
        lastName: null,
        phone: null,
        email: null,
        msg: null,
      },

      properties: [
        { id: 1, imageUrl: 'public/images/pages/properties/property-1.jpg', name: 'Margarita valley', price: 7000000, toilets: 3, toilets_partial: 1, bedrooms: 3, size_interior: '11.204 Sq Ft', size_extrerio: '' },
        { id: 1, imageUrl: 'public/images/pages/properties/property-2.jpg', name: 'Villa de los Angeles', price: 7000000, toilets: 3, toilets_partial: 1, bedrooms: 3, size_interior: '11.204 Sq Ft', size_extrerio: '' },
        { id: 1, imageUrl: 'public/images/pages/properties/property-3.jpg', name: 'Vista hermosa', price: 7000000, toilets: 3, toilets_partial: 1, bedrooms: 3, size_interior: '11.204 Sq Ft', size_extrerio: '' },
        { id: 1, imageUrl: 'public/images/pages/properties/property-4.jpg', name: 'Villa Angeles', price: 7000000, toilets: 3, toilets_partial: 1, bedrooms: 3, size_interior: '11.204 Sq Ft', size_extrerio: '' },

        { id: 1, imageUrl: 'public/images/pages/properties/property-4.jpg', name: 'Villa Angeles', price: 7000000, toilets: 3, toilets_partial: 1, bedrooms: 3, size_interior: '11.204 Sq Ft', size_extrerio: '' },
        { id: 1, imageUrl: 'public/images/pages/properties/property-3.jpg', name: 'Vista hermosa', price: 7000000, toilets: 3, toilets_partial: 1, bedrooms: 3, size_interior: '11.204 Sq Ft', size_extrerio: '' },
        { id: 1, imageUrl: 'public/images/pages/properties/property-2.jpg', name: 'Villa de los Angeles', price: 7000000, toilets: 3, toilets_partial: 1, bedrooms: 3, size_interior: '11.204 Sq Ft', size_extrerio: '' },
        { id: 1, imageUrl: 'public/images/pages/properties/property-1.jpg', name: 'Margarita valley', price: 7000000, toilets: 3, toilets_partial: 1, bedrooms: 3, size_interior: '11.204 Sq Ft', size_extrerio: '' },
      ],

      id:null,

      modal:{
        msg:'',
        icon:'',
        block:false,
      },

      // == Variables para datepicker y timepicker ==
      minDate: null,
      dateFormOpts: { year: 'numeric', month: 'numeric', day: 'numeric' },
      // == ==

      // == Sharing ==
      sharing: {
        url: 'https://sothebystest.club/rm/property/',
        title: 'Riviera Maya Sotheby’s',
        description: 'Descripcion',
        quote: 'x quote',
        hashtags: 'x hashtags',
        twitterUser: 'twitter'
      },

      networks: [
        //{ network: 'twitter', name: 'Twitter', icon: 'fab fa-twitter' },
        { network: 'facebook', name: 'Facebook', icon: 'fab fa-facebook-f' },
        { network: 'pinterest', name: 'Pinterest', icon: 'fab fa-pinterest-p' },
      ],
      // == ==

      // == Carousel options ==
      galleryOptions: {
        loop: false,
        speed: 750,
        effect: 'fade',

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
          clickable: true,
        },

        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        },

      },

      propertiesOptions: {
        loop: true,
        slidesPerView: 4,
        spaceBetween: 30,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        breakpoints: {
          1430: {
            slidesPerView: 4,
          },
          992: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 2,
          },
          1: {
            slidesPerView: 1,
          },
        }
      },
      // == ==
      recaptchaVerified:false,

    }
  },
  watch:{
      '$route.params.id':function(){

				this.id=this.$route.params.id;
        this.getData();

      }
  },
  methods: {
    setIndex(index) {
      this.index = index
    },

    dateDisabled(ymd, date) {
      // Disable weekends (Sunday = `0`, Saturday = `6`)
      const weekday = date.getDay();
      const day = date.getDate();
      // Return `true` if the date should be disabled
      return weekday === 0 || weekday === 6
    },

    formatNum(num) {
      let x = Intl.NumberFormat("en-US").format(num);
      return x;
    },

    onSubmitSchedule(event) {
        event.preventDefault();
        this.modal.block = true;
        this.modal.icon = "";
        this.modal.msg = "Loading...";
        this.$refs.modal.open();

        this.formSchedule.properties_id = this.id;
        axios.post(tools.url("/api/visit"),this.formSchedule).then((response)=>{

          this.formAgent = {
            name: null,
            email: null,
            date: null,
            time:null
          }

          this.modal.block = false;
          this.modal.icon = "success";
          this.modal.msg = "Thank you for contacting us! We will get back to you as soon as possible.";
        }).catch((error)=>{
          this.modal.block = false;
          this.modal.icon = "error";
          this.modal.msg = "Error saving information";
        });
    },

    onSubmitAgent(event) {
      event.preventDefault();

      if(this.recaptchaVerified == true){
        this.modal.block = true;
        this.modal.icon = "";
        this.modal.msg = "Loading...";
        this.$refs.modal.open();

        this.formAgent.properties_id = this.id;
        //this.formAgent.agents_id = this.id;
        axios.post(tools.url("/api/contactagents"),this.formAgent).then((response)=>{

          this.formAgent = {
            name: null,
            lastname:null,
            email: null,
            phone: null,
            msg:null
          }

          this.modal.block = false;
          this.modal.icon = "success";
          this.modal.msg = "Thank you for contacting us! We will get back to you as soon as possible.";
          this.$refs.recaptcha.reset();
        }).catch((error)=>{

          this.modal.block = false;
          this.modal.icon = "error";
          this.modal.msg = "Error saving information";

        });
      }
      else{
        this.modal.block = false;
          this.modal.icon = "warning";
          this.modal.msg = "Complete the captcha";
          this.$refs.modal.open();
      }
    },

    getData(){
      this.showGallery = false;

      this.$root.modal_loading = true;
      axios.get(tools.url("/api/properties/"+this.id)).then((response)=>{
        this.property = response.data;
        this.showGallery = true;

        for (var i = 0; i < this.property.images.length; i++) {
          this.gallery.push(this.property.images[i].url);
        }
        this.$root.modal_loading = false;
      }).catch((error)=>{
        console.error('Error getting data');
        this.$root.modal_loading = false;
      });
    },

    getPdf(){
      axios.get(tools.url("/api/propertiespdf/"+this.id)).then((response)=>{
        window.open(response.data);
      }).catch((error)=>{
      });
    },
    formatNum(num) {
      let x = Intl.NumberFormat("en-US").format(num);
      return x;
    },
    handleSuccess(response) {
      this.recaptchaVerified = true;

    }
  },
  //insert the following code for vue-meta to work
  /*metaInfo() {
      return {
            meta: [
              { name: 'og:title', content: 'Mexico Sotheby’s'},
                { name: 'og:description', content: this.property.name},
                { name: 'og:keywords', content: this.property.keywords},
                { name: 'og:image', content: this.property.images[0].url},

            ]
        }
  },*/
  beforeMount(){
    const now = new Date();
    this.minDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  },
  mounted(){
    if(this.$route.params.id){
				this.id=this.$route.params.id;
        this.getData();
    }
  }
}
</script>
